<template>
    <div>
        
        <a-modal
        
        v-model="modals"
        @ok="handleOk"
        :footer="null"
        width:="640px"
        :centered="true"
        
        >
          <div slot="title">
           Informasi Status
          </div>
          
          <div class="plan-content">

            <div class="infostatus">
                <div>
                    <h3 style="color:rgb(0, 196, 196)">Menunggu Verifikasi</h3>
                    <p>Claim sedang dalam proses verifikasi oleh tim medis kami.</p>
                </div>
                <div>
                    <h3 style="color:#333">Hold</h3>
                    <p>Polis sedang dalam proses perpanjangan. Proses claim akan dilanjutkan kembali setelah proses perpanjangan polis selesai</p>
                </div>
                 <div>
                    <h3 style="color:#EE5843">Pending</h3>
                    <p>Ada dokumen yang kurang. Mohon dapat segera dilengkapi. Informasi kekurangan dokumen dapat dilihat dalam detail claim</p>
                </div>
                <div>
                    <h3 style="color:#FF9900">Mohon Lengkapi Kekurangan Dokumen</h3>
                    <p>Claim sudah diajukan, namun masih ada dokumen yang dibutuhkan oleh asuransi. Informasi kekurangan dokumen dapat dilihat dalam detail claim</p>
                </div>

                <div>
                    <h3 style="color:#4C88FF">Sedang di-review oleh Asuransi</h3>
                    <p>Claim sudah diajukan dan sedang dalam proses, mohon menunggu ya!</p>
                </div>

                <div>
                    <h3 style="color:#6464FF">Proses Banding ke Asuransi</h3>
                    <p>Claim ditolak oleh asuransi namun sedang kami bantu ajukan banding...harap bersabar...</p>
                </div>
                <div>
                    <h3 style="color:#EA9999">Tidak dicover</h3>
                    <p>Mohon maaf claim yang diajukan tidak dicover oleh asuransi</p>
                </div>
                <div>
                    <h3 style="color:#20CC86">Disetujui dibayarkan Sebagian</h3>
                    <p>Sebagian claim sudah disetujui untuk dicover. Perinciannya dapat diliat dalam detail claim</p>
                </div>

                <div>
                    <h3 style="color:#00b407">Disetujui dibayarkan Penuh</h3>
                    <p>Selamat!! Asuransi sudah menyetujui seluruh pembayaran claim anda</p>
                </div>
            </div>
          </div>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    beforeCreate(){
        this.form = this.$form.createForm(this,{name:'FormAction'})
    },
    data() {
        return {
            modals: false,
        
            isLoading: false,
          
          
        }
    },
      mounted() {
          document.body.classList.add("ModalCustom");
        
        
        
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
  },
    methods: {
        moment,
        handleOk() {
            this.modals = false
        },
        handleCancel() {
            this.modals = true            
        },
       
        Show(item) {
          
            this.modals = true
         

        
            
        },
        
    }
}
</script>

<style>
.ModalCustom .ant-modal-content{
    padding:2px 5px !important;
   
   
}

.ModalCustom  .ant-modal-header{
 border-radius: 24px !important;

 }

 .ModalCustom .ant-modal-close-x{
    margin-right: 20px;
 }

</style>
